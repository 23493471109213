<template>
<div class="examin" v-loading="loading">
   <div class="left">
      <h3>考试视频</h3>
      <div class="video">
        <div id="player"></div>
      </div>
   </div>   
   <div class="right">
      <h3>上传考试视频</h3>
      <div class="upload">
        <i class="el-icon-circle-close" v-show="videoUrl" @click="videoUrl=''"></i>
        <video controls :src="videoUrl" v-show="videoUrl"/>
        <img src="@/assets/images/videoUpload.png"/>
        <input v-show="!videoUrl" type="file" ref="videoFile" accept=".mp4,.AVI,.mov,.rmvb,.rm,.FLV" @change="uploadData"/>
      </div>
      <p>考生须知：<br/>
请先看完考试视频，按照考试视频操作，录制视频后
上传，视频大小不超过200M</p>
      <div class="btn" @click="submit">交卷</div>  
   </div>
      
</div>
</template>

<script>
import { upload } from "@/utils/obs.js";
export default {
  name: "lesson",
  data() {
    return {
      loading:false,
      info:{},
      videoUrl:'',
    };
  },
  async mounted() {
    this.info = window.localStorage.getItem('videoInfo') ? JSON.parse(window.localStorage.getItem('videoInfo')) : {}
    if(this.info){
      this.getVideo()
      this.videoUrl = this.info.url
    }
  },
  destroyed() {
   
  },
  computed: {
   
  },
  methods: {
  
    async uploadData(e) {
      let file = e.target.files[0];
      if (file.size > 1024 * 1024 * 200) {
        this.$toast("请上传200M以内的视频");
        return;
      }
      this.loading = true
      let res = await upload(file, `wuhanExamin`);
      this.loading = false
      this.videoUrl = res
    },
    async submit(){
      if(!this.videoUrl){
        this.$toast("请上传视频");
        return
      }
      this.loading = true
      let res = await this.$api({
        method: "get",
        url: "/api/test/tong/video",
        data: {
            discipline_id: this.info.discipline_id,
            url: this.videoUrl,
            test_id: this.info.id,
            token: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).access_token : ''
          },
      });
      this.loading = false
      if(res.code == 0){
        this.$router.replace('./examinVideoResult')
      }else{
        this.$toast(res.msg);
      }
    
    },
    async getVideo() {
     
      let videoInfo = this.info.resource;

      let id = videoInfo.ali_media_id;
      if (id) {
        let res = await this.$api({
          method: "get",
          url: "/api/video/play-auth/get",
          data: {
            video_id: id,
          },
        });
        if (res.code == 0) {
          new Aliplayer(
            {
              id: "player",
              width: "100%",
              height: "450px",
              vid: res.data.videoId,
              playauth: res.data.playAuth,
              skinLayout: [
                {
                  name: "bigPlayButton",
                  align: "blabs",
                  x: '50%',
                  y: '50%',
                },
                {
                  name: "H5Loading",
                  align: "cc",
                },
                {
                  name: "errorDisplay",
                  align: "tlabs",
                  x: 0,
                  y: 0,
                },
                {
                  name: "infoDisplay",
                },
                {
                  name: "tooltip",
                  align: "blabs",
                  x: 0,
                  y: 56,
                },
                {
                  name: "thumbnail",
                },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    {
                      name: "progress",
                      align: "blabs",
                      x: 0,
                      y: 44,
                    },
                    {
                      name: "playButton",
                      align: "tl",
                      x: 15,
                      y: 12,
                    },
                    {
                      name: "timeDisplay",
                      align: "tl",
                      x: 10,
                      y: 7,
                    },
                    {
                      name: "fullScreenButton",
                      align: "tr",
                      x: 10,
                      y: 12,
                    },
                    {
                      name: "subtitle",
                      align: "tr",
                      x: 15,
                      y: 12,
                    },
                    {
                      name: "volume",
                      align: "tr",
                      x: 5,
                      y: 10,
                    },
                  ],
                },
              ],
            },
            function (player) {
              // console.log("The player is created.");
            }
          );
        } else {
          this.$toast(res.msg);
        }
      } else if (videoInfo.url) {
        new Aliplayer(
          {
            id: "player",
            width: "100%",
            height: "450px",

            source: videoInfo.url,
            cover: "",
            skinLayout: [
              {
                name: "bigPlayButton",
                align: "blabs",
                x: '50%',
                y: '50%',
              },
              {
                name: "H5Loading",
                align: "cc",
              },
              {
                name: "errorDisplay",
                align: "tlabs",
                x: 0,
                y: 0,
              },
              {
                name: "infoDisplay",
              },
              {
                name: "tooltip",
                align: "blabs",
                x: 0,
                y: 56,
              },
              {
                name: "thumbnail",
              },
              {
                name: "controlBar",
                align: "blabs",
                x: 0,
                y: 0,
                children: [
                  {
                    name: "progress",
                    align: "blabs",
                    x: 0,
                    y: 44,
                  },
                  {
                    name: "playButton",
                    align: "tl",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "timeDisplay",
                    align: "tl",
                    x: 10,
                    y: 7,
                  },
                  {
                    name: "fullScreenButton",
                    align: "tr",
                    x: 10,
                    y: 12,
                  },
                  {
                    name: "subtitle",
                    align: "tr",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "volume",
                    align: "tr",
                    x: 5,
                    y: 10,
                  },
                ],
              },
            ],
          },
          function (player) {
            // console.log("The player is created.");
          }
        );
      }else if(videoInfo.ccid){
        
        window.createCCH5Player({
          vid: videoInfo.ccid,
          siteid: "23EEB4513490D389",
          // vid: 'AA22BDEE61E7455A9C33DC5901307461',
          // siteid: "2661F9756E5C832E",
          autoStart: true,
          playertype: 1,
          isShare: "false",
          banDrag: "false",
          width: "100%",
          height: "450px",
          loop: "true",
          showFullScreenBtn: "false",
          useX5Play: "true",
          isShowUrlBtn: "false",
          isShowMoreBtn: "false",
          objectFit: "cover", // contain 、 fill 、 cover
          parentNode: "#player",
          showFullScreenBtn: true
        });
        window.on_h5player_error = (err) => {
          console.log('失败')
          console.log(err)
        }
        window.on_CCH5player_ready = (obj)=>{
          console.log('ready')

        };
        
        
      }
      
      
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.examin {
  // min-height: calc(100vh - 330px);
  display: flex;
  justify-content: center;
  color: #333333;
  width:1200px;
  background: #f6f9fc;
  border-radius: 8px;
  margin: 20px auto;
  padding: 0 30px 40px;
  h3{
    font-size: 22px;
    color: #333;
    margin: 20px 0;
  }
  .left{
    margin-right: 20px;
    .video{
      width: 800px;
      height: 450px;
    }
    
  }
  .right{
    flex: 1;
    display: flex;
    flex-direction: column;
    .upload{
      width: 100%;
      position: relative;
      input{
        opacity: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 10;
        position: absolute;
      }
      video{
        width:100%;
        height: 100%;
        position:absolute;
        left:0;
        top:0;
      }
      .el-icon-circle-close{
        position:absolute;
        top:-5px;
        right:-5px;
        cursor: pointer;
        font-size:20px;
        z-index:5;
      }
    }
    p{
      font-size: 14px;
      line-height: 24px;
      color: #666666;
      padding-top: 20px;
      flex: 1;
    }
    .btn{
      width: 100%;
      height: 53px;
      text-align: center;
      line-height: 53px;
      border-radius: 4px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
      background: #2e66d0;
    }
  }
}
</style>

<style lang="scss">
</style>
